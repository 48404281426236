$dark-color:#0E0E0E;
$bg-color:#06060c;
$grey-color:#0b0d13;
$light-grey-color:#1b1e26;
$extra-light-grey-color:#1b1e26;
$white-color:#F1F1F1;
$txt-color-1:#FFFFFF;
$txt-color-2:#b7b7b7;
$txt-color-3:#636468;
$logo-color:#F2C838;
$primary-color:#FFCF23;
$secondary-color:#7A5800;





// $dark-color:#0E0E0E;
// $bg-color:#161616;
// $grey-color:#27292E;
// $light-grey-color:#252525;
// $extra-light-grey-color:#3a3a3a;
// $white-color:#F1F1F1;
// $txt-color-1:#FFFFFF;
// $txt-color-2:#C3C3C3;
// $txt-color-3:#636468;
// $logo-color:#F2C838;
// $primary-color:#FFCF23;
// $secondary-color:#7A5800;