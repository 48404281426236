@import './colors';
.reset-pass {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 60px;
    gap: 20px;
    background-color: $bg-color;
    input {
        color: $txt-color-2;
        padding: 5px 10px;
        width: 340px;
        height: 40px;
        border: 1px solid $txt-color-3;
        border-radius: 4px;
        background-color: rgba($color: #ffffff, $alpha: 0.1);
    }
    button {
        width: 340px;
        height: 40px;
        border-radius: 4px;
        background-color: $primary-color;
        &:hover {
            outline: 4px solid rgba($color: $primary-color, $alpha: 0.4);
        }
    }
}