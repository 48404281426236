@import './colors';
// animations
@keyframes none-trans-to-full {
    0% {
        opacity: 0.0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes semi-trans-to-none {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-up {
    0% {
        opacity: 0.0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0.0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slide-left {
    0% {
        opacity: 0.0;
        transform: translateX(60px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes slide-right {
    0% {
        opacity: 0.0;
        transform: translateX(-60px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.home {
    background-color: $bg-color;
    .landing-page {
        position: relative;
        background-size: cover;
        background-position: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        .logo-txt {
            z-index: 2;
            opacity: 0.4;
            color: $txt-color-1;
        }
        .description {
            text-align: center;
            color: $txt-color-1;
            z-index: 2;
            font-weight: 300;
            p {
                opacity: 0.0;
                transform: translateY(50px);
            }
        }
        .over-lay {
            position: absolute;
            background-color: rgba($color: #000000, $alpha: 0.5);
            backdrop-filter: blur(0.1rem);
            width: 100%;
            height: 100%;
            z-index: 1;
            border-bottom: 2px solid $white-color;
        }
        &.active {
            .logo-txt {
                animation: semi-trans-to-none 0.3s forwards;
                animation-delay: 0.4s;
            }
            .description {
                p {
                    animation: slide-up 0.6s forwards;
                    animation-delay: var(--d);
                }
            }
        }
    }
    .first-article {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: red;
        background-color: $dark-color;
        .first-art-holder {
            overflow: hidden;
        }
        &.flip-dir {
            background-color: transparent;
        }
        .candles-image {
            opacity: 0.4;
        }
        .lessons-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            .list-heading {
                color: $txt-color-1;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon-holder {
                    border-radius: 50%;
                    border: 4px solid $white-color;
                    background-color: $bg-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transform: translateX(60px);
                    &.no-b {
                        border: 4px solid transparent;
                    }
                    .icon {
                        display: flex;
                    }
                }
                p {
                    opacity: 0;
                    transform: translateX(-60px);
                }
            }
            .points {
                display: flex;
                flex-direction: column;
                li {
                    display: flex;
                    gap: 10px;
                    opacity: 0;
                    transform: translateY(60px);
                    .txt {
                        color: $txt-color-1;
                        .b-txt {
                            font-weight: 700;
                        }
                    }
                    .icon {
                        display: flex;
                        color: $primary-color;
                        transform: translateY(2px);
                    }
                }
            }
        }
        .line {
            background: linear-gradient(to bottom, transparent, $white-color, transparent);
            opacity: 0;
        }
        &.active {
            .candles-image {
                animation: semi-trans-to-none 0.3s forwards;
                animation-delay: 0.4s;
            }
            .line {
                animation: none-trans-to-full 0.5s forwards;
            }
            .lessons-list {
                .icon-holder {
                    animation: slide-left 0.5s forwards;
                }
                p {
                    animation: slide-right 0.5s forwards;
                }
                .points {
                    li {
                        animation: slide-up 0.5s forwards;
                        animation-delay: var(--d);
                    }
                }
            }
        }
    }
    .second-article {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        background-color: $dark-color;
        overflow-x: hidden;
        overflow-y: hidden;
        .btc-image {
            opacity: 0.4;
            border-radius: 50%;
            margin-bottom: 20px;
        }
        .first-txt {
            opacity: 0.4;
            color: $txt-color-1;
            font-weight: 400;
        }
        .icon {
            color: $primary-color;
            display: flex;
            opacity: 0;
            transform: translateY(-30px);
        }
        .logo-txt {
            color: $txt-color-1;
            font-weight: 400;
            opacity: 0;
            transform: translateY(30px);
        }
        .logo-image {
            opacity: 0;
            transform: translateY(30px);
        }
        .intro-txt {
            color: $txt-color-3;
            opacity: 0;
            transform: translateY(30px);
        }
        .no-1 {
            color: $txt-color-1;
            text-align: center;
            font-weight: 200;
            opacity: 0;
            transform: translateX(60px);
            b {
                font-weight: 800;
            }
        }
        .line-image {
            opacity: 0;
            transform: translateX(-60px);
            margin-top: 15px;
            margin-bottom: 20px;
        }
        .txt-underline {
            color: $txt-color-1;
            text-align: center;
            margin-top: 20px;
            p {
                opacity: 0;
                transform: translateY(60px);
            }
        }
        .txt-underline {
            font-weight: 200;
            b {
                font-weight: 600;
            }
        }
        .the-two-images {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .monitor-chart {
                opacity: 0.4;
            }
            .phone-image {
                opacity: 0.4;
            }
        }
        &.active {
            .btc-image {
                animation: semi-trans-to-none 0.3s forwards;
            }
            .first-txt {
                animation: semi-trans-to-none 0.3s forwards;
            }
            .icon {
                animation: slide-down 0.3s forwards;
                animation-delay: 0.3s;
            }
            .logo-txt {
                animation: slide-up 0.3s forwards;
                animation-delay: 0.4s;
            }
            .logo-image {
                animation: slide-up 0.3s forwards;
                animation-delay: 0.5s;
            }
            .intro-txt {
                animation: slide-up 0.3s forwards;
                animation-delay: 0.6s;
            }
            .no-1 {
                animation: slide-left 0.5s forwards;
                animation-delay: 0.9s;
            }
            .line-image {
                animation: slide-right 0.5s forwards;
                animation-delay: 1s;
            }
            .txt-underline {
                p {
                    animation: slide-up 0.4s forwards;
                    animation-delay: var(--d);
                }
            }
            .monitor-chart {
                animation: semi-trans-to-none 0.3s forwards;
                animation-delay: 1.3s;
            }
            .phone-image {
                animation: semi-trans-to-none 0.3s forwards;
                animation-delay: 1.3s;
            }
        }
    }
    .third-article {
        display: flex;
        flex-direction: column;
        align-items: center;
        .article-line {
            width: 100%;
            height: 4px;
            background: linear-gradient(to left, transparent, $white-color, transparent);
            opacity: 0;
        }
        .article-heading {
            color: $txt-color-1;
            text-align: center;
            opacity: 0;
        }
        .some-txt {
            color: $txt-color-1;
            text-align: center;
            opacity: 0;
        }
        .cards-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            z-index: 10;
            .card {
                position: relative;
                background-color: $extra-light-grey-color;
                border-radius: 20px;
                border: 1px solid #ffffff5c;
                opacity: 0;
                transform: translateY(60px);
                user-select: none;
                transition: 0.3s;
                cursor: pointer;
                &:hover {
                    border: 1px solid #ffffffa3;
                    background-color: #2e3138;
                }
                .card-heading-holder {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    .groups-icon {
                        width: 60px;
                    }
                    .card-heading {
                        color: $txt-color-1;
                    }
                }
                .desc {
                    position: absolute;
                    color: $txt-color-1;
                    text-align: center;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .channel-image {
                    position: absolute;
                    border-radius: 16px;
                }
            }
        }
        &.active {
            .article-line {
                animation: none-trans-to-full 0.3s forwards;
            }
            .article-heading {
                animation: none-trans-to-full 0.3s forwards;
                animation-delay: 0.2s;
            }
            .some-txt {
                animation: none-trans-to-full 0.3s forwards;
                animation-delay: 0.4s;
            }
            .cards-holder {
                .card {
                    animation: slide-up 0.3s forwards;
                    animation-delay: var(--d);
                }
            }
        }
    }
}
@media only screen and (min-width:1420px) {
    .home {
        .landing-page {
            background-image: url('../../public/images/l-2.png');
            height: calc(100vh - 80px);
            gap: 20px;
            .description {
                max-width: 900px;
            }
        }
        .home-holder {
            grid-column: 1/13;
        }
        .first-article {
            height: 700px;
            .first-art-holder {
                grid-column: 1/13;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .lessons-list {
                height: 300px;
                width: 520px;
                .list-heading {
                    gap: 20px;
                    .icon-holder {
                        width: 90px;
                        height: 90px;
                        .icon {
                            display: flex;
                            font-size: 40px;
                        }
                    }
                }
                .points {
                    gap: 30px;
                }
            }
            .line {
                height: 500px;
                width: 4px;
            }
            .candles-image {
                width: 520px;
            }
        }
        .second-article {
            padding-top: 80px;
            .second-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .btc-image {
                width: 90px;
            }
            .first-txt {
                font-size: 32px;
            }
            .icon {
                font-size: 80px;
                margin: 20px 0 0 0;
            }
            .logo-txt {
                font-size: 42px;
            }
            .logo-image {
                margin-top: 40px;
                width: 160px;
            }
            .intro-txt {
                margin-top: 20px;
            }
            .no-1 {
                margin-top: 20px;
            }
            .line-image {
                width: 400px;
            }
            .txt-underline {
                width: 600px;
            }
            .the-two-images {
                margin-top: 20px;
                gap: 100px;
                .monitor-chart {
                    width:560px;
                }
                .phone-image {
                    width:560px;
                }
            }
        }
        .third-article {
            .third-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .article-heading {
                margin-top: 40px;
            }
            .some-txt {
                margin-top: 40px;
                width: 500px;
            }
            .cards-holder {
                gap: 40px;
                margin: 40px 0px;
                .card {
                    width: 420px;
                    height: 520px;
                    .card-heading-holder {
                        gap: 20px;
                        top: 40px;
                        .channel-icon {
                            width: 50px;
                        }
                    }
                    .desc {
                        top: 110px;
                        width: 380px;
                    }
                    .channel-image {
                        width: 380px;
                        left: 20px;
                        bottom: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:1120px) and (max-width: 1420px){
    .home {
        .landing-page {
            background-image: url('../../public/images/l-2.png');
            height: calc(100vh - 80px);
            gap: 20px;
            .description {
                max-width: 900px;
            }
        }
        .home-holder {
            grid-column: 1/13;
        }
        .first-article {
            height: 700px;
            .first-art-holder {
                grid-column: 1/13;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .lessons-list {
                height: 300px;
                width: 520px;
                .list-heading {
                    gap: 20px;
                    .icon-holder {
                        width: 90px;
                        height: 90px;
                        .icon {
                            display: flex;
                            font-size: 40px;
                        }
                    }
                }
                .points {
                    gap: 30px;
                }
            }
            .line {
                height: 500px;
                width: 4px;
            }
            .candles-image {
                width: 520px;
            }
        }
        .second-article {
            padding-top: 80px;
            .second-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .btc-image {
                width: 90px;
            }
            .first-txt {
                font-size: 32px;
            }
            .icon {
                font-size: 80px;
                margin: 20px 0 0 0;
            }
            .logo-txt {
                font-size: 42px;
            }
            .logo-image {
                margin-top: 40px;
                width: 160px;
            }
            .intro-txt {
                margin-top: 20px;
            }
            .no-1 {
                margin-top: 20px;
            }
            .line-image {
                width: 400px;
            }
            .txt-underline {
                width: 600px;
            }
            .the-two-images {
                margin-top: 20px;
                gap: 100px;
                .monitor-chart {
                    width:420px;
                }
                .phone-image {
                    width:420px;
                }
            }
        }
        .third-article {
            .third-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .article-heading {
                margin-top: 40px;
            }
            .some-txt {
                margin-top: 40px;
                width: 500px;
            }
            .cards-holder {
                gap: 40px;
                margin: 50px 0px;
                .card {
                    width: 420px;
                    height: 500px;
                    .card-heading-holder {
                        gap: 20px;
                        top: 40px;
                        .channel-icon {
                            width: 50px;
                        }
                    }
                    .desc {
                        top: 105px;
                        width: 380px;
                    }
                    .channel-image {
                        width: 380px;
                        left: 20px;
                        bottom: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:768px) and (max-width: 1120px) {
    .home {
        .landing-page {
            background-image: url('../../public/images/l-2-ip.png');
            // background-image: url('../../public/images/landing-image-ip.png');
            height: calc(100vh - 80px);
            gap: 20px;
            .description {
                max-width: 550px;
            }
        }
        .home-holder {
            grid-column: 1/13;
        }
        .first-article {
            padding-top: 40px;
            gap: 20px;
            flex-direction: column-reverse;
            align-items: center;
            .first-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: space-between;
            }
            &.flip-dir {
                .first-art-holder {
                    flex-direction: column;
                }
            }
            .lessons-list {
                height: 260px;
                width: 520px;
                .list-heading {
                    gap: 20px;
                    .icon-holder {
                        width: 90px;
                        height: 90px;
                        .icon {
                            display: flex;
                            font-size: 40px;
                        }
                    }
                }
                .points {
                    gap: 30px;
                }
            }
            .line {
                display: none;
            }
            .candles-image {
                width: 480px;
                margin: 90px 0;
            }
        }
        .second-article {
            padding-top: 80px;
            .second-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .btc-image {
                width: 90px;
            }
            .first-txt {
                font-size: 32px;
            }
            .icon {
                font-size: 80px;
                margin: 20px 0 0 0;
            }
            .logo-txt {
                font-size: 42px;
            }
            .logo-image {
                margin-top: 40px;
                width: 160px;
            }
            .intro-txt {
                margin-top: 20px;
            }
            .no-1 {
                margin-top: 20px;
            }
            .line-image {
                width: 300px;
            }
            .txt-underline {
                width: 500px;
            }
            .the-two-images {
                margin-top: 40px;
                .monitor-chart {
                    width: 480px;
                }
                .phone-image {
                    display: none;
                }
            }
        }
        .third-article {
            .third-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .article-heading {
                margin-top: 40px;
            }
            .some-txt {
                margin-top: 40px;
                width: 500px;
            }
            .cards-holder {
                gap: 40px;
                margin: 50px 0px;
                .card {
                    width: 420px;
                    height: 480px;
                    .card-heading-holder {
                        gap: 20px;
                        top: 40px;
                        .channel-icon {
                            width: 50px;
                        }
                    }
                    .desc {
                        top: 105px;
                        width: 380px;
                    }
                    .channel-image {
                        width: 380px;
                        left: 20px;
                        bottom: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:480px) and (max-width: 768px){
    .home {
        .landing-page {
            background-image: url('../../public/images/l-2-ph.png');
            height: calc(100vh - 70px);
            gap: 20px;
            .description {
                max-width: 450px;
            }
        }
        .home-holder {
            grid-column: 1/7;
        }
        .first-article {
            padding-top: 60px;
            gap: 20px;
            flex-direction: column-reverse;
            align-items: center;
            .first-art-holder {
                grid-column: 1/7;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: space-between;
            }
            &.flip-dir {
                .first-art-holder {
                    flex-direction: column;
                }
            }
            .lessons-list {
                height: 260px;
                width: 440px;
                .list-heading {
                    gap: 20px;
                    .icon-holder {
                        width: 90px;
                        height: 90px;
                        .icon {
                            display: flex;
                            font-size: 40px;
                        }
                    }
                }
                .points {
                    gap: 30px;
                }
            }
            .line {
                display: none;
            }
            .candles-image {
                width: 400px;
                margin: 100px 0;
            }
        }
        .second-article {
            padding-top: 60px;
            .second-art-holder {
                grid-column: 1/7;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .btc-image {
                width: 60px;
            }
            .first-txt {
                font-size: 20px;
            }
            .icon {
                font-size: 42px;
                margin: 20px 0 0 0;
            }
            .logo-txt {
                font-size: 28px;
            }
            .logo-image {
                margin-top: 20px;
                width: 100px;
            }
            .intro-txt {
                margin-top: 20px;
            }
            .no-1 {
                margin-top: 20px;
            }
            .line-image {
                width: 280px;
            }
            .txt-underline {
                width: 380px;
            }
            .the-two-images {
                margin-top: 40px;
                .monitor-chart {
                    width: 420px;
                }
                .phone-image {
                    display: none;
                }
            }
        }
        .third-article {
            .third-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .article-heading {
                margin-top: 60px;
            }
            .some-txt {
                margin-top: 30px;
                width: 400px;
            }
            .cards-holder {
                gap: 20px;
                margin: 30px 0px;
                .card {
                    width: 400px;
                    height: 480px;
                    .card-heading-holder {
                        gap: 10px;
                        top: 20px;
                        .groups-icon {
                            width: 45px;
                        }
                    }
                    .desc {
                        top: 100px;
                        width: 360px;
                    }
                    .channel-image {
                        width: 360px;
                        left: 20px;
                        bottom: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:370px) and (max-width: 480px){
    .home {
        .landing-page {
            background-image: url('../../public/images/l-2-ph.png');
            height: calc(100vh - 70px);
            gap: 20px;
            .description {
                max-width: 350px;
            }
        }
        .home-holder {
            grid-column: 1/7;
        }
        .first-article {
            padding-top: 60px;
            gap: 20px;
            flex-direction: column-reverse;
            align-items: center;
            .first-art-holder {
                grid-column: 1/7;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: space-between;
            }
            &.flip-dir {
                .first-art-holder {
                    flex-direction: column;
                }
            }
            .lessons-list {
                height: 220px;
                width: 340px;
                .list-heading {
                    gap: 20px;
                    .icon-holder {
                        width: 80px;
                        height: 80px;
                        .icon {
                            display: flex;
                            font-size: 40px;
                        }
                    }
                }
                .points {
                    gap: 20px;
                }
            }
            .line {
                display: none;
            }
            .candles-image {
                width: 340px;
                margin: 90px 0;
            }
        }
        .second-article {
            padding-top: 60px;
            .second-art-holder {
                grid-column: 1/7;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .btc-image {
                width: 60px;
            }
            .first-txt {
                font-size: 20px;
            }
            .icon {
                font-size: 42px;
                margin: 20px 0 0 0;
            }
            .logo-txt {
                font-size: 28px;
            }
            .logo-image {
                margin-top: 20px;
                width: 100px;
            }
            .intro-txt {
                margin-top: 20px;
            }
            .no-1 {
                margin-top: 20px;
            }
            .line-image {
                width: 260px;
            }
            .txt-underline {
                width: 320px;
            }
            .the-two-images {
                margin-top: 40px;
                .monitor-chart {
                    width: 320px;
                }
                .phone-image {
                    display: none;
                }
            }
        }
        .third-article {
            .third-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .article-heading {
                margin-top: 40px;
            }
            .some-txt {
                margin-top: 20px;
                width: 320px;
            }
            .cards-holder {
                gap: 20px;
                margin: 30px 0px;
                .card {
                    width: 340px;
                    height: 420px;
                    .card-heading-holder {
                        gap: 10px;
                        top: 20px;
                        .groups-icon {
                            width: 45px;
                        }
                    }
                    .desc {
                        top: 80px;
                        width: 300px;
                    }
                    .channel-image {
                        width: 310px;
                        left: 15px;
                        bottom: 15px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:370px){
    .home {
        .landing-page {
            background-image: url('../../public/images/l-2-ph.png');
            height: calc(100vh - 70px);
            gap: 20px;
            .description {
                max-width: 260px;
            }
        }
        .home-holder {
            grid-column: 1/7;
        }
        .first-article {
            padding-top: 60px;
            gap: 20px;
            flex-direction: column-reverse;
            align-items: center;
            .first-art-holder {
                grid-column: 1/7;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: space-between;
            }
            &.flip-dir {
                .first-art-holder {
                    flex-direction: column;
                }
            }
            .lessons-list {
                height: 300px;
                width: 260px;
                .list-heading {
                    gap: 20px;
                    .icon-holder {
                        width: 80px;
                        height: 80px;
                        .icon {
                            display: flex;
                            font-size: 40px;
                        }
                    }
                }
                .points {
                    gap: 20px;
                }
            }
            .line {
                display: none;
            }
            .candles-image {
                width: 260px;
                margin: 90px 0;
            }
        }
        .second-article {
            padding-top: 60px;
            .second-art-holder {
                grid-column: 1/7;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .btc-image {
                width: 60px;
            }
            .first-txt {
                text-align: center;
                font-size: 20px;
            }
            .icon {
                font-size: 42px;
                margin: 20px 0 0 0;
            }
            .logo-txt {
                font-size: 28px;
            }
            .logo-image {
                margin-top: 20px;
                width: 100px;
            }
            .icon {
                font-size: 80px;
                margin: 20px 0;
            }
            .logo-image {
                margin-top: 20px;
                width: 110px;
            }
            .intro-txt {
                margin-top: 20px;
            }
            .no-1 {
                margin-top: 20px;
            }
            .line-image {
                width: 240px;
            }
            .txt-underline {
                width: 260px;
            }
            .the-two-images {
                margin-top: 40px;
                .monitor-chart {
                    width: 280px;
                }
                .phone-image {
                    display: none;
                }
            }
        }
        .third-article {
            .third-art-holder {
                grid-column: 1/13;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .article-heading {
                margin-top: 40px;
            }
            .some-txt {
                margin-top: 20px;
                width: 260px;
            }
            .cards-holder {
                gap: 20px;
                margin: 30px 0px;
                .card {
                    width: 260px;
                    height: 380px;
                    .card-heading-holder {
                        gap: 5px;
                        top: 20px;
                        .groups-icon {
                            width: 40px;
                        }
                    }
                    .desc {
                        top: 80px;
                        width: 250px;
                    }
                    .channel-image {
                        width: 250px;
                        left: 5px;
                        bottom: 5px;
                    }
                }
            }
        }
    }
}